import { useEffect, useState } from 'react';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { GnosisSafe } from '@web3-react/gnosis-safe';
import { WalletConnect } from '@web3-react/walletconnect-v2';

type GnosisConnectionTypes = 'none' | 'gnosis-default' | 'gnosis-wallet-connect';

function checkIfMetadataUrlIsSafe(provider: Web3Provider): boolean {
  // signer is not present on ExternalProvider yet it exists, so we extend the type
  type ExtendedProvider = ExternalProvider & { signer?: any };
  const extendedProvider = provider.provider as ExtendedProvider;
  const isMetadataUrlSafe = extendedProvider.signer?.session?.peer?.metadata?.url === 'https://app.safe.global';

  return isMetadataUrlSafe;
}

const useIsGnosisSafe = (): GnosisConnectionTypes => {
  const { isActive, provider, connector } = useWeb3React();
  const [gnosisConnectionType, setGnosisConnectionType] = useState<GnosisConnectionTypes>('none');

  useEffect(() => {
    // Detect if connection is via Gnosis Safe by comparing connector instance
    if (!isActive || !provider || !connector) return;

    let connection: GnosisConnectionTypes = 'none';

    const isSafeConnector = connector instanceof GnosisSafe;
    const isWalletConnectConnector = connector instanceof WalletConnect;
    const isMetadataUrlSafe = checkIfMetadataUrlIsSafe(provider);
    const isGnosisSafeWithWalletConnect = isWalletConnectConnector && isMetadataUrlSafe;

    // This means the app has connected via SafeApp in Gnosis UI. User (a signer of connected safe) can be connected to Gnosis via MetaMask or WalletConnect, but that is a layer above the base SafeConnector connection via SafeApp.
    if (isSafeConnector) connection = 'gnosis-default';

    // This means the user has made a connection to the webapp from their Gnosis Safe. The difference from the above is that in this case the webapp is running as normal in the browser, rather than via SafeApp
    // The user will have used the WalletConnect SafeApp and pasted in the QR code from the Webapp into Gnosis Safe UI.
    if (isGnosisSafeWithWalletConnect) connection = 'gnosis-wallet-connect';

    setGnosisConnectionType(connection);
  }, [isActive, provider, connector]);

  return gnosisConnectionType;
};

export default useIsGnosisSafe;
