import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';

// Components
import MapleContainer from 'Components/ds/MapleContainer';
import TypographyComplex from 'Components/ds/TypographyComplex';

const FooterDisclaimer: FC = (): ReactElement => {
  return (
    <MapleContainer maxWidth='xl'>
      <Box sx={{ borderTopWidth: '2px', borderTopStyle: 'solid', borderTopColor: 'ds.neutral.200', pt: 1, pb: 3 }}>
        <TypographyComplex
          align='left'
          items={[
            {
              copy: 'Maple is a technology services provider. Use of the Maple Protocol involves risks, including but not limited to the potential loss of digital assets. Before using the Maple Protocol, you should review our',
              type: 'copy',
            },
            { copy: 'documentation', type: 'link', url: 'https://maplefinance.gitbook.io/maple/' },
            {
              copy: 'to ensure you understand how the Protocol works. As described in our',
              type: 'copy',
            },
            {
              copy: 'Terms',
              type: 'link',
              url: 'https://maplefinance.gitbook.io/maple/additional-links/interface-terms-of-use',
            },
            {
              copy: ', the Maple Protocol is provided on an “as is” and “as available” basis, at your own risk. We explicitly disclaim any representation or warranties of any kind relating to the Protocol, and no developer or entity will be liable for claims or damages of any kind associated with use or inability to use the Protocol. Maple is not engaged in the business of the offer, sale, or trading of securities. The Protocol token, Syrup (SYRUP), is a utility token that is not tied to profits or growth of the Protocol or efforts of Maple. Purchasers should have no expectation of profit and should not consider it an investment. Holding, lending, or borrowing digital assets involve a substantial degree of risk, including the risk of complete loss. The information contained on this website is intended for informational purposes only. Although Maple strives to ensure accuracy of that information, some communications may not reflect the most current information, and Maple cannot guarantee that all content is updated, complete, reliable, or accurate.',
              type: 'copy',
            },
          ]}
          color='ds.neutral.500'
          variant='paragraphSmall'
          globalWeight='regular'
        />
      </Box>
    </MapleContainer>
  );
};

export default FooterDisclaimer;
